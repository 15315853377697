// External Imports
import React, { useState, useEffect, Fragment } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
// Config
import { ScrollSpy } from "../../../exports/functions.exports";
import { colors } from "../../../exports/assets.exports";
import { urlRoutes } from "../../../exports/constants.exports";
import { ILinkOptionProps } from "../../../exports/types.exports";
// Components
import {
  BlogsCallToAction,
  Breadcrumb,
  DrawerSidebar,
  SEO,
  ButtonRow,
  ImageElement,
  NoteElement,
  TipElement,
  HeaderText,
  BodyText,
  DividerElement,
  ListElement,
  ListOfContentsComponent,
} from "../../../exports/components.exports";
import {
  Box,
  Container,
  Grid,
  Typography,
  Avatar,
  Divider,
  Stack,
  Chip,
} from "@mui/material";
import { Person } from "@mui/icons-material";

/**
 * @constant query for strapi
 */
export const query = graphql`
  query PostQuery($slug: String!) {
    strapiPost(slug: { eq: $slug }) {
      strapiId
      title
      description
      body
      updated_at(formatString: "YYYY/MM/DD")
      created_at(formatString: "YYYY/MM/DD")
      writer {
        name
        picture {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
      }
      DynamicContent
      post_category {
        Name
        slug
      }
      feature_image {
        localFile {
          publicURL
        }
      }
      tags {
        title
      }
      assets {
        localFile {
          childrenImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          url
        }
      }
    }
    allStrapiPostCategory {
      edges {
        node {
          slug
          Name
        }
      }
    }
  }
`;

/**
 * @function Post component
 * @param data
 * @returns the blog post component that renders the content from strapi
 */
const Post = ({ data }: { data: any }) => {
  const postCategories = data.allStrapiPostCategory.edges;
  const post = data.strapiPost;
  const avatarImage = getImage(data.strapiPost?.writer?.picture?.localFile);
  const [listOfContents, setListOfContents] = useState<Array<string>>([]);
  const {
    DynamicContent,
    body,
    description,
    feature_image,
    post_category,
    title,
    updated_at,
    writer,
    tags,
  } = post;
  let tagStringForMetaDescription = tags.map((tag: any) => tag.title).join(" ");

  const seo = {
    metaTitle: title,
    metaDescription: description + " " + tagStringForMetaDescription,
    shareImage: feature_image,
    article: true,
  };

  /**
   * @function renderOptions
   * @returns the list of links for the drawer sidebar
   */
  const renderOptions = () => {
    const dropdownOptions: Array<ILinkOptionProps> = [];

    postCategories.map((category: any) =>
      dropdownOptions.push({
        href: `${urlRoutes.BLOG}/${category.node.slug}`,
        text: category.node.Name,
      })
    );

    return dropdownOptions;
  };

  /**
   * @function useEffect
   * sets the headers for the list of contents, this is only shown on desktop/laptop screens
   */
  useEffect(() => {
    let tmpHeaderList: Array<string> = [];
    DynamicContent.map((dynamicItem: any) => {
      if (dynamicItem.strapi_component === "guide-components.text-variant") {
        tmpHeaderList.push(dynamicItem.Text);
      }
    });
    setListOfContents(tmpHeaderList);
  }, []);

  /**
   * @function getDynamicComponent switch statement to map over all data and render the equivalent components for the data
   * @param allDynamicContent all the data for the guide from strapi
   * @returns each individual component that is setup in this project and on the strapi backend
   */
  const getDynamicComponent = (allDynamicContent: any) => {
    return allDynamicContent.map((content: any, index: number) => {
      switch (content.strapi_component) {
        case "guide-components.body-text":
          return (
            <BodyText
              key={`${content.strapi_component}-${index}`}
              bodyText={content}
            />
          );
        case "guide-components.line-break":
          return (
            <DividerElement key={`${content.strapi_component}-${index}`} />
          );
        case "guide-components.text-variant":
          return (
            <HeaderText
              key={`${content.strapi_component}-${index}`}
              headerText={content}
            />
          );
        case "guide-components.numbered-list":
          return (
            <ListElement
              key={`${content.strapi_component}-${index}`}
              listData={content.ListElement}
              bulletPoint={false}
            />
          );
        case "shared.image-component":
          return (
            <ImageElement
              key={`${content.strapi_component}-${index}`}
              imageData={content}
            />
          );
        case "guide-components.note":
          return (
            <NoteElement
              key={`${content.strapi_component}-${index}`}
              nodeText={content}
            />
          );
        case "guide-components.tip":
          return (
            <TipElement
              key={`${content.strapi_component}-${index}`}
              tipText={content}
            />
          );
        case "guide-components.bulleted-list":
          return (
            <ListElement
              key={`${content.strapi_component}-${index}`}
              listData={content.ListElement}
              bulletPoint={true}
            />
          );
        case "guide-components.button-row":
          return (
            <ButtonRow
              buttonData={content.Button}
              key={`${content.strapi_component}-${index}`}
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <Fragment>
      <SEO seo={seo} />
      <BlogsCallToAction />
      <ScrollSpy>
        <Box
          sx={{ marginBottom: "5rem", position: "relative" }}
          className="blogs-post"
        >
          <Container maxWidth="md">
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginY: "2rem",
              }}
            >
              <Breadcrumb
                primary={{ text: "Blogs", href: urlRoutes.BLOG }}
                secondary={{
                  text: `${post_category.Name}`,
                  href: `${urlRoutes.BLOG}/${post_category.slug}`,
                }}
                currentPage={title}
              />
              <Box>
                <DrawerSidebar listItems={renderOptions()} />
              </Box>
            </Box>
          </Container>
          <Divider sx={{ marginBottom: "5rem" }} />
          {listOfContents.length >= 1 && (
            <ListOfContentsComponent contentList={listOfContents} />
          )}

          <Container maxWidth="md" className="blogs-post__container">
            <Grid container className="blogs-post__grid" spacing={4}>
              <Grid item xs={12} className="blogs-post__grid-item">
                <Typography
                  variant="h2"
                  className="blogs-post__title"
                  color={colors.swiftcaseDarkBlue}
                  sx={{ marginBottom: "2rem" }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="h5"
                  className="blogs-post__title"
                  color={colors.swiftcaseMidGrey}
                >
                  Last Updated {updated_at}
                </Typography>
              </Grid>
              {DynamicContent.length === 0 && body && (
                <Grid
                  item
                  xs={12}
                  className="blogs-post__grid-item"
                  id="markdown-content"
                >
                  <Markdown
                    rehypePlugins={[rehypeRaw]}
                    children={body}
                    className="post__content"
                  />
                </Grid>
              )}

              {DynamicContent.length >= 1 &&
                getDynamicComponent(DynamicContent)}

              <Grid
                item
                xs={12}
                className="blogs-post__grid-item"
                sx={{ display: "inline-flex", alignItems: "center" }}
              >
                {avatarImage ? (
                  <GatsbyImage
                    loading="lazy"
                    style={{
                      width: "4rem",
                      height: "4rem",
                      borderRadius: "50%",
                      marginRight: "1rem",
                    }}
                    image={avatarImage}
                    alt={writer.name ?? "The Swiftcase Team"}
                  />
                ) : (
                  <Avatar
                    sx={{
                      marginRight: "1rem",
                      height: "4rem",
                      width: "4rem",
                      backgroundColor: colors.swiftcaseDarkBlue,
                    }}
                  >
                    <Person />
                  </Avatar>
                )}
                <Typography
                  variant="h5"
                  className="blogs-post__footer-written-by"
                  color={colors.swiftcaseMidGrey}
                >
                  By {writer.name ?? "The Swiftcase Team"}
                </Typography>
              </Grid>
              {tags.length >= 1 && (
                <Grid item xs={12} className="blogs-post__grid-item--tags">
                  <Stack spacing={1} alignItems="center" direction="row">
                    <Typography
                      variant="h4"
                      className="blogs-post__header-text"
                      color={colors.swiftcaseDarkBlue}
                    >
                      Tags:
                    </Typography>
                    {tags.map((tag: any) => (
                      <Chip label={tag.title} color="secondary" size="small" />
                    ))}
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      </ScrollSpy>
    </Fragment>
  );
};

export default Post;
